div#flags {
  display: flex;
  gap: 15px;
  img {
      width: 40px;
      height: auto;
      opacity: 0.5;
      transition: all .3s;
      @include mq($until: xl) {
        width: 30px;
      }    
      &:hover {
          opacity: 1;
      }
  }

  .active-lang  {
      img {
          opacity: 1;
      }
  }

  @include mq($until: md) {
    margin-left: 15px;
    margin-top: 15px;
    img {
      width: 30px;
    }
  }
}
