.catalog-map {
    margin-bottom: 100px;
    .map__content {
        height: 80vh;
    }
    .map__popup {
        &-logo {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            border-radius: 12px 12px 0 0;
        }
        &-image {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            background-size: cover;
            border-radius: 12px 12px 0 0;
        }
        &-address {
            font-size: 14px;
        }
        &-content {
            padding: 15px;
        }
        h4 {
            font-size: 18px;
        }
        .button {
            color: #fff;
            display: block;
            margin-top: 20px;
        }
    }
    .leaflet-popup-content {
        margin: 0;
    }
}

.catalog-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    &--col2 {
        grid-template-columns: repeat(2, 1fr);
    }
    &--col3 {
        grid-template-columns: repeat(3, 1fr);
    }
    &--col4 {
        grid-template-columns: repeat(4, 1fr);
    }
}

