.apartment-widgets{
    margin-top: 180px;
    h2 {
        text-align: center;
        font-size: rem(40px);
    }
}
.apartment-widget {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 180px;
    margin-bottom: 180px;
    background: #fff;
    border-radius: 13px;
    margin-left: 5vw;
    @include mq($until: 1399px) {
        grid-template-columns: 100%;
        margin-left: auto;
        max-width: 900px;
        margin-right: auto;
    }
    &:nth-child(2n+2) {
        margin-left: 0;
        margin-right: 5vw;
        @include mq($until: 1399px) {
            margin-left: auto;
            margin-right: auto;
        }
        .apartment-widget__slider {
            order: 2;
            left: unset;
            right: -5vw;
            @include mq($until: 1399px) {
                order: 0;
            }
            @include mq($until: lg) {
                right: 0;
            }

        }
        .slick-arrow {
            right: unset;
            left: 20px;
            &:last-of-type {
                right: unset;
                left: 90px;
            }
        }
        .apartment-widget__content {
            padding: 40px 0 20px 60px;
            @include mq($until: 1399px) {
                padding: 40px 60px 40px 60px;
            }
            @include mq($until: sm) {
                padding: 30px;
            }
    
        }
        .apartment-widget__price {
            margin-left: 0;
            margin-right: 5vw;
            @include mq($until: 1399px) {
                right: -5vw;
                left: unset;
                border-radius: 10px 0 0 10px;
            }    
        }
    }
    &__slider {
        left: -5vw;
        margin-top: -80px;
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        @include mq($until: lg) {
            left: 0;
        }
        img {
            max-width: 1920px;
            width: 100%;
            height: auto;
            border-radius: 13px;
        }
        .slide-photo {
            width: 100%;
            height: 100%;
            background-size: cover;
        }
        .slick-slide * {
            font-size: 0;
        }
        .slick-arrow {
            position: absolute;
            right: 20px;
            bottom: 20px;
            border-radius: 100%;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(5px);
            transition: ease 0.3s all;
            width: 50px;
            height: 50px;
            padding: 15px;
            z-index: 9;
            stroke: $color-extra;
            &:first-of-type {
                transform: rotate(180deg);
                right: 90px;
            }
            &:hover {
                background: $color-main;
                stroke: #fff;
                cursor: pointer;
            }
            line {
                stroke-width: 2;
            }
        }
    }
    &__content {
        padding: 40px 60px 20px 0;
        display: flex;
        row-gap: 40px;
        flex-direction: column;
        justify-content: space-between;
        @include mq($until: 1399px) {
            padding: 40px 60px 40px 60px;
        }
        @include mq($until: sm) {
            padding: 30px;
        }
    }
    &__price {
        font-size: rem(21px);
        text-align: center;
        margin-left: 5vw;
        padding: 20px;
        color: $color-extra;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        @include mq($until: 1399px) {
            position: absolute;
            top: 80px;
            left: -5vw;
            background-color: #fff;
            border-radius: 0 10px 10px 0;
        }
        @include mq($until: sm) {
            top: 40px;
            font-size: rem(16px);
            padding: 10px 20px;
            flex-direction: column;
            gap: 0;
        }

        strong {
            text-transform: uppercase;
            font-weight: 700;
        }
    }
    h3 {
        padding-bottom: 20px;
        margin-bottom: 40px;
        text-transform: uppercase;
        font-size: rem(27px);
        border-bottom: 1px solid #A8B3C4;
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 60px;
        @include mq($until: 1399px) {
            flex-direction: row;
        }
        @include mq($until: lg) {
            flex-direction: column;
        }
        &-single {
            display: flex;
            column-gap: 40px;
            align-items: center;
            @include mq($until: sm) {
                column-gap: 20px;
            }
        }
        p {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 10px;
            color: $color-extra;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        svg {
            margin-left: 20px;
            width: 45px;
            height: auto;
            @include mq($until: sm) {
                margin-left: 0;
            }
            path {
                fill: $color-extra;
            }
        }
    }
    &__buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        @include mq($until: sm) {
            grid-template-columns: 1fr;
        }
        a {
            font-size: rem(18px);
            font-weight: 700;
            text-transform: uppercase;
            &.button--border {
                border-color: $color-extra;
                color: $color-extra;
                &:hover {
                    color: #fff;

                }
            }
        }
    }
}
