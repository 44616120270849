.catalog-card {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    transition: ease 0.3s all;
    position: relative;
    /*&:hover {
        box-shadow: 1px 1px 20px rgba($color-extra, 0.2)
    }*/
    &__logo {
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $color-extra;
    }
    &__image {
        width: 100%;
        //height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $color-extra;
        background-size: cover;
    }
    &__price {
        position: absolute;
        top: 40px;
        right: 0;
        color: $color-extra;
        background-color: #ffffff;
        border-radius: 10px 0 0 10px;
        font-size: rem(14px);
        padding: 10px 15px;
        strong {
            text-transform: uppercase;
            font-weight: 700;
        }
    }
    &__content {
        padding: 25px;
    }
    &__address {
        font-size: 15px;
        font-weight: 500;
        color: $color-extra;
        svg {
            width: 20px;
            fill: $color-extra;
            margin-right: 10px;
        }
    }
    &__head {
        font-size: rem(22px);
        margin-top: 0;
        transition: ease 0.3s all;
        &:hover {
            color: $color-main;
        }
    }
    &__more {
        margin-top: 25px;
        font-size: rem(18px);
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        gap: 10px;
        .button {
            &:last-of-type {
                flex: 1;
            }
            &--border {
                border-color: $color-extra;
                color: $color-extra;
            }
        }
    }
}
