.info {
    padding-top: 180px;
    padding-bottom: 180px;
    display: flex;
    justify-content: center;
    @include mq($until: md){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    &__content {
        max-width: 1240px;
        width: 100%;
        h2 {
            text-align: center;
            font-size: rem(37px);
            text-transform: uppercase;
            margin-bottom: 80px;
            @include mq($until: md){
                font-size: rem(32px);
            }
        }
        p {
            text-align: center;
            line-height: 30px;
            margin-bottom: 40px;
            &:last-of-type {
                margin-bottom: 0;
            }            
        }
    }
}
