.features {
    padding-top: 180px;
    padding-bottom: 180px;
    position: relative;
    background-position: center;
    background-size: cover;
    @include mq($until: md){
        padding-top: 100px;
        padding-bottom: 100px;
    }
    &__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        h2 {
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: rem(27px);
            font-weight: 700;
        }
        p {
            text-align: center;
            color: #fff;
            font-weight: 500;
            font-size: 17px;
            line-height: 35px;
            max-width: 920px;
        }
        .icons-set {
            margin-top: 60px;
            margin-bottom: 60px;
            gap: 3vw;
            @include mq($until: xs){
                width: 100%;
                gap: 40px;
            }
            &__item {
                border: 0;
                row-gap: 30px;
                column-gap: 30px;
                height: unset;
                @include mq($until: xs){
                    display: flex;
                    flex-direction: row;
                    text-align: left;
                }
                @include mq($until: 340px){
                    flex-direction: column;
                    text-align: center;
                    padding: 0;
                }
                &[data-hover]:not([data-hover*='fade']):hover img:not([data-hover*='fade']) {
                    background-color: transparent;
                }
                &-content {
                    @include mq($until: xs){
                        max-width: 200px;
                        width: 100%;
                    }
                }
                &-text {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: rem(21px);
                    font-weight: 700;
                }
                img {
                    border-radius: 0;
                    max-width: 120px;
                    width: 100%;
                    @include mq($until: xs){
                        max-width: 80px;
                    }
                }
            }
        }
    }
}
