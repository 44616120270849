.readmore {
  &__prev {

  }

  &__more {
    display: none;
  }

  &__button {
    font-size: rem(14px);
    font-style: italic;
    cursor: pointer;
    display: block;
    width: fit-content;
    margin-left: auto;
  }
}