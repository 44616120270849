.footer {
  background-color: $color-extra;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  gap: 100px;
  justify-content: space-between;
  align-items: center;
  @include mq($until: md){
    flex-direction: column;
    gap: 60px;
  }
  @include mq($until: 620px){
    gap: 30px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    row-gap: 50px;
    max-width: 800px;
    @include mq($until: md){
      max-width: 100%;
      width: 100%;
    }
    @include mq($until: 620px){
      flex-direction: column;
      row-gap: 30px;
    }
  }
  &__social {
    display: flex;
    align-items: center;    
    span {
      color: #fff;
      font-size: 14px;
      margin-right: 20px;
      @include mq($until: 620px){
        text-transform: capitalize;
        font-size: rem(19px);
        font-weight: 500;
      }
    }
    a {
      opacity: 0.5;
      transition: ease 0.3s all;
      &:hover {
        opacity: 1;
      }
    }
    svg {
      fill: #fff;
      width: 27px;
    }
  }
  &__nav {
    display: flex;
    width: 100%;
    @include mq($until: md){
      order: -1;
    }
    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      @include mq($until: 620px){
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
      }
    }
    li {
      margin: 0!important;
      width: auto!important;
    }
    a {
      color: #fff;
      padding: 0;
    }
  }
  &__contact {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include mq($until: md){
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
    @include mq($until: 620px){
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
    }
    span {
      color: #fff;
      font-size: rem(19px);
      font-weight: 500;
      margin-bottom: 10px;
      @include mq($until: md){
        margin: 0;
        flex: 1;
      }
    }
    a {
      display: flex;
      align-items: center;
      gap: 20px;
      color: #fff;
      font-size: 14px;
      &:hover {
        color: $color-main;
      }
      &:hover svg {
        fill: $color-main;
      }
    }
    svg {
      fill: #fff;
      width: 20px;
    }
  }
  &-small{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: #F8F8F8;
      @include mq($until: 700px){
        flex-direction: column;
        gap: 10px;
      }
      span, div, a {
        font-size: 14px;
        color: #59595B;
      }
      a:hover {
        color: $color-main;
      }
      &__realization{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span{
          transition: color .3s ease-in-out;
        }
        &:hover span{
          color: $color-main;
        }
        img{
          margin-left: 15px;
        }
      }
  }
}
